import React, { useEffect, useState } from 'react';
import {
  Layout,
  Space,
  Typography,
  Select,
} from 'antd';
import { bindActionCreators } from 'redux';
import { actions } from '../actions';
import { connect } from 'react-redux';
import './header.css';

const { Option } = Select;
const { Header } = Layout;
const { Text } = Typography;

const mapStateToProps = ({ components }) => ({ components });
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch)

const AppHeader = ({
  components,
  fetchUnits,
  setFilter,
}) => {

  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [state, setState] = useState(null)
  const [city, setCity] = useState(null)

  useEffect(() => {
    fetchUnits()
  }, [fetchUnits])

  useEffect(() => {
    if (state) {
      const stateUnits = components.units.filter(
        item => item.status && item.showWhatsApp && `${item.state} - ${item.region}` === state
      )
      const cities = groupAttributes(stateUnits, 'city')

      setCities(cities.sort((a, b) => a.localeCompare(b)))
    }
  }, [components.units, state])

  useEffect(() => {
    if (city) {
      setFilter(city)
    }
  }, [components.units, setFilter, city])


  const groupAttributes = (array, attribute)  => {
    return Object.keys(
      array.reduce((groups, item) => {
        const value = attribute === 'state'
          ? `${item[attribute]} - ${item['region']}`
          : item[attribute]

        groups[value] = groups[value] || []
        groups[value].push(item)
        return groups
      }, {})
    )
  }

  useEffect(() => {
    if (components.units) {
      const countryUnits = components.units.filter(item => item.status && item.showWhatsApp && item.country === 'Brasil')
      const states = groupAttributes(countryUnits, 'state')
      setStates(states.sort((a, b) => a.localeCompare(b)))
    }
  }, [components.units])

  return (
    <Header
      style={{
        background: '#182F1B 0% 0% no-repeat padding-box',
        height: '330px',
        borderRadius: '20px 20px 0px 0px',
      }}
    >
      <Space direction={'vertical'} style={{ lineHeight: 0 }}>
        <img
          className={'logo-sd'}
          src="logo_sobrancelhas-design.png"
          alt="Sobrancelhas Design"
        />
        <Text className={'description'}>
          Escolha a sua cidade e fale no WhatsApp com a SD mais próxima de você:
        </Text>
        <Select
          showSearch
          style={{ width: '100%', marginTop: '20px', marginBottom: '8px' }}
          placeholder="Estado - UF"
          optionFilterProp="children"
          onChange={setState}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {
            states.map(
              item => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              )
            )
          }
        </Select>
        <Select
          showSearch
          style={{ width: '100%' }}
          placeholder="Cidade"
          optionFilterProp="children"
          onChange={setCity}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {
            cities.map(
              item => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              )
            )
          }
        </Select>
      </Space>
    </Header>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
